<template>
  <v-dialog
      v-model="dialog"
      max-width="600"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} Faq</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                  v-model="faq.name"
                  :error="$v.faq.name.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Name <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.faq.name.$error">Name is required</span>
              <span class="text-danger" v-if="errors.name" >* {{ errors.name[0] }}</span>
            </v-col>

            <v-col cols="12">
              <v-select
                  item-text="name"
                  item-value="id"
                  :items="faq_categories"
                  v-model="faq.category_ids"
                  :error="$v.faq.category_ids.$error"
                  @input="faq.category_ids = $event !== null ? $event : ''"
                  multiple
                  small-chips
                  deletable-chips
                  menu-props="closeOnContentClick"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Categories <span class="text-danger">*</span>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item
                      ripple
                      @click="selectAllCategories"
                  >
                    <v-list-item-action>
                      <v-icon :color="faq.category_ids.length > 0 ? 'indigo darken-4' : ''">
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select All
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-1"></v-divider>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.faq.category_ids.$error">Category is required</span>
              <span class="text-danger" v-if="errors.category_ids" >* {{ errors.category_ids[0] }}</span>
            </v-col>

            <v-col cols="12">
              Description <span class="text-danger">*</span>
              <ckeditor
                :config="editorConfig"
                v-model="faq.description"
              >
              </ckeditor>
              <span class="text-danger" v-if="$v.faq.description.$error">Description Field is required</span>
              <span class="text-danger" v-if="errors.description" >* {{ errors.description[0] }}</span>
            </v-col>

            <v-col cols="12" md="6">
              Status
              <v-switch
                  v-model="faq.is_active"
                  :label="faq.is_active ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            @click="closeDialog">
          Cancel
        </v-btn>

        <v-btn
            v-if="checkIsAccessible('faq', 'create') || checkIsAccessible('faq', 'edit')"
            class="btn btn-primary"
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FaqService from "@/services/cms/faq/FaqService";
import FaqCategoryService from "@/services/cms/faq/category/FaqCategoryService";
import { required } from "vuelidate/lib/validators";

const faq = new FaqService();
const faqCategory = new FaqCategoryService();

export default {
  name: "CreateAndUpdate",
  data() {
    return {
      title: '',
      edit: false,
      dialog: false,
      loading: false,
      scores: [],
      faq_categories: [],
      errors: [],
      faq: {
        name: '',
        position: '',
        category_ids: [],
        score_id: '',
        description: '',
        is_active: true,
      },

      editorConfig: {
        versionCheck: false,
        toolbar: [
          [
            'Font', 'FontSize', 'FontColor', 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList', 'TextColor', 'BGColor'
          ]
        ],
        extraPlugins: ['font', 'colorbutton'],
        colorButton_enableMore: true,
        allowedContent: true
      }
    }
  },
  validations: {
    faq: {
      name: { required },
      description: { required },
      category_ids: { required },
      is_active: { required }
    }
  },
  mounted() {
    this.getAllParent();
  },
  computed: {
    selectAllCategory () {
      return this.faq.category_ids.length === this.faq_categories.length
    },
    selectedCategory () {
      return this.faq.category_ids.length > 0 && !this.selectAllCategory
    },
    icon () {
      if (this.selectAllCategory) return 'mdi-close-box'
      if (this.selectedCategory) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods: {
    closeDialog(){
      this.dialog = false;
    },
    createFAQ(){
      this.title = 'Add';
      this.dialog = true;
      this.edit = false;
      this.resetForm();
    },
    editFAQ(faq){
      this.title = "Edit"
      this.dialog = true;
      this.edit = true;
      this.faq = faq;
    },
    getAllParent() {
      faqCategory
          .getParent()
          .then(response => {
            this.faq_categories = response.data.data;
          })
          .catch((err) => {
          });
    },
    selectAllCategories () {
      this.$nextTick(() => {
        if (this.selectAllCategory) {
          this.faq.category_ids = []
        } else {
          this.faq.category_ids = [];
          this.faq_categories.forEach(category => {
            this.faq.category_ids.push(category.id);
          });
        }
      })
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function() {
      faq
          .create(this.faq)
          .then(response =>{
            this.loading = false;
            this.$snotify.success("FAQ created successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    update() {
      faq
          .update(this.faq.id,this.faq)
          .then(response => {
            this.loading = false;
            this.$snotify.success("FAQ updated successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    resetForm(){
      this.$v.$reset();
      this.faq = {
        name: '',
        position: '',
        category_ids: [],
        score_id: '',
        description: '',
        is_active: true,
      }
    }
  }
}
</script>

<style scoped>

</style>
