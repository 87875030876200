<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>FAQ</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ FAQ
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn v-if="checkIsAccessible('faq', 'create')"
                      @click="createFaq()"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add FAQ
                  </v-btn>
                  <v-btn
                      @click="faqCategory()"
                      class="mt-1 ml-3 btn btn-standard"
                  >
                    FAQ Category
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <v-row>
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field
                        label="Name"
                        v-model="search.name"
               ve
                        @input="search.name = $event !== null ? $event : ''"
                        clearable
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <v-select
                        :items="categories"
                        v-model="search.category_ids"
                        v-on:keyup.enter="searchFaq"
                        @input="search.category_ids = $event !== null ? $event : ''"
                        label="Faq Category"
                        item-text="name"
                        item-value="id"
                        clearable
                        outlined
                        dense
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="6" md="2">
                    <v-select
                        label="Status"
                        v-model="search.is_active"
                        v-on:keyup.enter="searchFaq"
                        @input="search.is_active = $event !== null ? $event : ''"
                        :items="status"
                        item-text="name"
                        item-value="value"
                        clearable
                        outlined
                        dense
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="6" md="2">
                    <v-btn
                        @click.prevent="searchFaq"
                        v-on:keyup.enter="searchFaq"
                        class="btn btn-primary"
                        :loading="loading"
                    >
                      <v-icon small  outlined>fas fa-search</v-icon>&nbsp;
                      Search
                    </v-btn>
                  </v-col>
                </v-row>
            </div>

            <!-- table -->
            <div class="table-responsive">

              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th><strong>Name</strong></th>
                  <th><strong>Position</strong></th>
                  <th><strong>Categories</strong></th>
                  <th><strong>Status</strong></th>
                  <th class="pr-3 text-center"><strong>Action</strong></th>
                </tr>
                </thead>

                <template>
                  <draggable class="w-100" v-model="faqs" @change="sort" @start="drag=true" tag="tbody" @end="drag=false">
                    <tr v-for="(faq, index) in faqs" :key="index">
                      <td>
                        <a @click="editFaq(faqCategory)" class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                          <i class="fa fa-sort"></i> {{ faq.name }}
                        </a>
                      </td>
                      <td>
                        {{ faq.position }}
                      </td>
                      <td>
                        <ul v-if="faq.categories">
                          <li v-for="category in faq.categories" :key="category.id">
                            {{ category.name }}
                          </li>
                        </ul>
                        <span class="font-weight-bolder text-dark-65" v-else>
                          N/A
                        </span>
                      </td>

                      <td>
                        <span class="badge badge-success text-lg`"
                              v-bind:class="{ 'badge-success': faq.is_active, 'badge-danger': !faq.is_active }"
                        >{{ faq.is_active ? 'Active' : 'Inactive' }}</span>
                      </td>

                      <td class="pr-0 text-left">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('faq', 'edit')">
                                <a class="navi-link" @click="editFaq(faq)">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('faq', 'delete')">
                                <a class="navi-link" @click.prevent="deleteFaq(faq.id)">
                                  <span class="navi-icon">
                                      <v-icon color="red darken-2">fas fa-trash-alt</v-icon>
                                  </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="faqs.length == 0">
                      <td class="text-center font-weight-bold px-3" colspan="6">No Data Found</td>
                    </tr>
                  </draggable>
                </template>

              </table>

            </div>
            <!-- table ends -->
            <v-overlay :value="isSorting">
              <v-progress-circular
                  indeterminate
                  color="dark"
              ></v-progress-circular>
            </v-overlay>

          </div>
        </div>
      </div>
      <create-and-update ref="create-and-update" @refresh="getAllFaqs"></create-and-update>
    </div>
  </v-app>
</template>
<script>
import FaqService from "@/services/cms/faq/FaqService";
import CreateAndUpdate from "@/view/pages/view/cms/faq/CreateAndUpdate";
import FaqCategoryService from "@/services/cms/faq/category/FaqCategoryService";
import draggable from "vuedraggable";

const faq = new FaqService();
const faqCategory = new FaqCategoryService();

export default {
  components: {
    CreateAndUpdate,
    draggable
  },
  data() {
    return{
      faqs:[],
      categories:[],
      total: null,
      perPage: null,
      page: null,
      loading: false,
      isSorting: false,
      status: [
        {name: 'All', value: ''},
        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ],
      search:{
        name:'',
        category_ids: '',
        is_active:'',
      }
    }
  },
  mounted() {
    this.getAllCategories();
  },
  methods: {
    faqCategory(){
      this.$router.push({
        name: "faq-category",
      });
    },
    createFaq(){
      this.$refs['create-and-update'].createFAQ();
    },
    editFaq(faq){
      this.$refs['create-and-update'].editFAQ(faq);
    },
    getAllFaqs(){
      this.loading = true;
      faq
      .paginate(this.search, this.page)
      .then(response => {
        this.faqs = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
        this.loading = false;
      })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    getAllCategories(){
      faqCategory
          .getParent()
          .then(response => {
            if(response.data.data.length > 0) {
              this.search.category_ids = response.data.data[0].id;
            }
            this.categories = response.data.data;
            this.getAllFaqs();
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    sort(){
      this.isSorting = true;
      faq
          .sort(this.faqs)
          .then(response => {
            this.isSorting = false;
            this.$snotify.success('Faq Sorted !!');
            this.getAllFaqs();
          })
          .catch(err => {
            this.isSorting = false;
            this.$snotify.error("Oops something went wrong");
          });
    },

    searchFaq(){
      this.getAllFaqs();
    },
    deleteFaq(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            faq
                .delete(id)
                .then((response) => {
                  this.getAllFaqs()
                  this.$snotify.success("FAQ Deleted Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    }
  }
}
</script>